

import { NotificationManager } from 'react-notifications'
import { webSocketMessageHandler } from '../websocket/WebsocketMessageHandler';
import checkUtils from '../util/checkUtils'
import log from 'loglevel';
import getCheckDetails from '../actions/getCheckDetails';


export default async function openCheckWs(check, checkId, restaurantId, personIdentifiers, table, debug = false) {
	
	return new Promise(async (resolve, reject) => {
		//IF THE CHECK CHANGES, CLEAR THE WS CONNECTION
		if (global.checkClient !== undefined && global.checkClient !== null && !global.isBypass && !global.checkClient.url.includes(check._id)) {
			if (global.checkClient) {
				// New connection is being made, close current connection
				global.checkClient.onclose = null // ensure the ws doesn't try to reconnect
				global.checkClient.close()
				global.checkClient = null
			}
		}

		var myInterval = null;
		let opened = false
		if (global.checkClient === undefined || global.checkClient === null) { /* WS is not listening to correct channel */
			const connect = () => {
				let token = localStorage.token
				let demo = check.restaurant.demo
				let wsUrl = `${global.dashServerWsUrl}?channel=check${check._id}&token=${token}&demo=${demo}`
				// log.info("CONNECTING TO WS URL: " + wsUrl)
				const client = new WebSocket(wsUrl);
				global.checkClient = client

				client.onopen = () => {
					log.info('Opening websocket connection')
					opened = true
					//ping the websocket to prevent the automatic 60 second timeout from ELB
					if (myInterval) clearInterval(myInterval)
					myInterval = setInterval(() => {
						client.send("ping")
					}, 30000);
				}

				client.onclose = () => {
					log.info('Closing websocket connection')
					if (myInterval) clearInterval(myInterval)
					//ATTEMPT TO AUTO RE-CONNECT TO THE WS SERVER EVERY SECOND IF DISCONNECTED
					setTimeout(function() {
						if (checkId !== undefined && checkId !== "" && opened) {
							// When the phone goes to sleep, reload the check details
							getCheckDetails(checkId, personIdentifiers, debug).then((check) => {
								let activePage = "ReceiptPage"
								if (global.textPay) {
									check.items.forEach((item) => {
										item.locked = true
										item.lockedByMe = true
										item.lockedBy = localStorage.firstName + " " + localStorage.lastName
										item.lockedByInitials = localStorage.initials
									})
									check = checkUtils.updateTotals(check, 0) // dumb hack to get the you pay set
									activePage = "PaymentPage"
								}
								global.set({
									check: check,
									restaurant: check ? check.restaurant : null,
									checkId: check._id,
									activePage: activePage,
									header: true,
									footer: false,
								});
								global.allowBack = false
								global.pageStack = []
								NotificationManager.warning('', 'Your phone lost connection, please start over.', 3000)
							}).catch((err) => {
								if (global.pageStack.length > 0) {
									NotificationManager.warning('', 'Problem loading reciept', 3000);
								}
								global.set({
									activePage: "ScanQrCodePage",
									header: false
								});
							})
						}
						opened = false
						connect()
					}, 3000)
				}

				client.onmessage = (message) => {
					// log.info("MSG: " + message.data)
					let updatedCheck = webSocketMessageHandler(JSON.parse(message.data), check);
					// log.info("UPDATED CHECK: ")
					// log.info(updatedCheck)
					if(updatedCheck != null){
						updatedCheck = checkUtils.updateTotals(updatedCheck)
						global.set({ check: updatedCheck })
					}
				}
			}
			// console.log("CONNECT OVER AND OVER")
			connect()
		} else {
			// REINIT ON MESSAGE TO ENSURE REFERENCE TO CHECK IS CURRENT
			global.checkClient.onmessage = (message) => {
				let updatedCheck = webSocketMessageHandler(JSON.parse(message.data), check);
				if(updatedCheck != null){
					updatedCheck = checkUtils.updateTotals(updatedCheck)
					global.set({ check: updatedCheck })
				}
			}
		}
	});
}